.LandingDevSample {
	padding-left: 126px;
    min-height: 200px;
    box-shadow: 0px 0px 30px 0px rgba(38, 84, 214, 0.30);
    margin-bottom: 20px;
    background-color: var(--white);
    background-repeat: no-repeat;
    background-position: left 40px center;
    background-size: 92px 92px;
}

.LandingDevSample .card-body {
    padding: 30px;
}

.LandingDevSample .title {
	font-family: 'onest-700';
	font-size: 20px;
}

.LandingDevSample .text {
	font-size: 14px;
	margin-top: 10px;
}

.LandingDevSample .apple-appstore-black {
    height: 30px;
    /* margin-left: 5px; */
    /* margin-top: -10px; */
    /* display: block; */
    
}