.preloader {
    position: fixed;
    /*display: none;*/
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background: rgba(0,0,0,.4) url(puff.svg) center center no-repeat;
}

.preloader.overall {
    z-index: 99999;
}