.TopMenu {
	padding-top: 13px;
	padding-left: 30px;
	padding-right: 30px;
}

.TopMenu .logo {
	height: 84px;
}

.TopMenu .navbar-nav {
	margin-left: 70px;
}

.TopMenu .navbar-nav .nav-item .nav-link {
	color: var(--blue) !important;
	/* color: #2654D6 !important; */
	font-family: 'onest-700';
	font-size: 20px;
	font-style: normal;
	/* font-weight: 700; */
	line-height: normal;
}

.TopMenu .contact-link {
	color: #2654D6;
	text-align: center;
	font-family: 'montserrat-600';
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	text-decoration: none;
}

.TopMenu .navbar-brand + .navbar-brand {
	margin-left: 30px;
}

.TopMenu .airse-logo {
	content: url(../img/airse-logo-4x.png);
	height: 88px;
}

.TopMenu .kfu-logo {
	content: url(../img/kfu-logo-4x.png);
	height: 32px;
}

.TopMenu .phone-col {
	padding-left: 0;
	padding-right: 12px;
}

.TopMenu .email-col {
	padding-left: 12px;
	padding-right: 0;
}