:root {
	--blue: #2654D6;
	--white: #fff;
	--black: #000;
	--card-shadow: 0px 0px 30px 0px rgba(38, 84, 214, 0.30);
	--border-radius: 10px;
	--light-blue: #EBEFFE;
	--dark-blue: rgba(29, 53, 159, 1);
	--btn-inactive-bg: rgba(152, 174, 238, 1);
	--link-hover-color: rgba(119, 154, 255, 1);
	--btn-hover-bg-color: rgba(18, 58, 173, 1);
}

@font-face {
	font-family: 'onest-400';
	src: url('./font/Onest/TTF/Onest-Regular.ttf');
}

@font-face {
	font-family: 'onest-500';
	src: url('./font/Onest/TTF/Onest-Medium.ttf');
}

@font-face {
	font-family: 'onest-700';
	src: url('./font/Onest/TTF/Onest-Bold.ttf');
}

@font-face {
	font-family: 'montserrat-400';
	src: url('./font/Montserrat/static/Montserrat-Regular.ttf');
}

@font-face {
	font-family: 'montserrat-500';
	src: url('./font/Montserrat/static/Montserrat-Medium.ttf');
}

@font-face {
	font-family: 'montserrat-600';
	src: url('./font/Montserrat/static/Montserrat-SemiBold.ttf');
}

@font-face {
	font-family: 'montserrat-700';
	src: url('./font/Montserrat/static/Montserrat-Bold.ttf');
}

* {
	cursor: default;
}

html {
	scroll-behavior: smooth;
}

body {
	/* font-size: 20px; */
	font-family: 'montserrat-400';
}

a {
	display: inline-block;
	color: var(--blue) !important;
	cursor: pointer;
	text-decoration: none;
}

a * {
	cursor: pointer;
}

a.btn-primary, a.btn.btn-primary:hover {
	color: var(--white) !important;
}

a.btn.btn-primary:hover {
	text-decoration: none !important;
}

a:hover {
	color: var(--blue) !important;
	text-decoration: underline !important;
}

b, .b, .bold {
	font-family: 'montserrat-600';
}

.btn {
	border-radius: 10px;
	box-shadow: 0px 0px 30px 0px rgba(38, 84, 214, 0.30);
	font-family: 'onest-700';
	font-size: 16px;
	font-style: normal;
	line-height: normal;
	border: none;
}

.btn.btn-primary {
	background-color: var(--blue);
}

.header {
	font-family: 'onest-700', serif;
}

h2, .h2 {
	font-family: 'onest-700', serif;
	font-size: 64px;
	color: var(--blue);
	text-align: center;
	letter-spacing: 2.56px;
	margin-bottom: 20px;
}

h3, .h3 {
	color: var(--blue);
	text-align: center;
	font-family: 'onest-700', serif;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

h4, .h4 {
	text-align: center;
	font-family: 'onest-700', serif;
	font-size: 24px;
}

.card {
	border-radius: var(--border-radius);
	border: none;
	background: var(--white);
}

.card .card-body {
	padding: 40px;
}

p {
	margin-top: 20px;
	margin-bottom: 0;
}

img.apple-appstore-black {
	content: url('./img/appstore-black.png');
}

input {
	cursor: text;
	background-color: var(--light-blue) !important;
	border: none !important;
}

label {
	font-family: onest-700;
	font-size: 16px;
	font-style: normal;
	line-height: normal;
	color: var(--dark-blue);
}

.bigger-text {
	font-size: 24px;
}

.smaller-text {
	font-size: 16px;
}

.smaller-text .bigger-text {
	font-size: 20px;
}

.clickable, .clickable * {
	cursor: pointer;
	display: inline-block;
}

.arrow-down-icon {
	content: url(img/arrow-down-4x.png);
	width: 16px;
}

.arrow-up-icon {
	content: url(img/arrow-up-4x.png);
	width: 16px;
}

.pre-line {
	white-space: pre-line;
}

.pre-line-forced {
	white-space: pre-line !important;
}

/* << lang-picker */

.lang-picker {
	display: inline-block;
	margin-left: 30px;
}

.lang-picker .lang {
	width: 37px;
}

.lang-picker .lang + .lang {
	margin-left: 20px;
}

.lang-picker .lang-active {
	width: 46px;
	/* filter: drop-shadow(0px 0px 30px rgba(38, 84, 214, 0.30)); */
	/* box-shadow: 0px 30px 0 0 rgba(38, 84, 214, 0.30); */
	box-shadow: var(--card-shadow);
	border-radius: 100%;
}

.lang-picker .lang-ru-RU {
	content: url(img/rus-4x.png)
}

.lang-picker .lang-en-GB {
	content: url(img/eng-4x.png)
}

/* >> lang-picker */

