.landing {
	padding-bottom: 60px;
}

/* << LANDING HEADER */

.landing .landing-header .slogan {
	color: var(--blue, #2654D6);
	text-align: center;
	font-family: 'onest-700';
	font-size: 64px;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.64px;
	margin-top: 80px;
}

.landing .landing-header .sub-slogan {
	color: var(--blue, #2654D6);
	text-align: center;
	font-family: 'montserrat-600';
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.96px;
	margin-top: 30px;
	white-space: pre-line;
}

.landing .landing-header .buttons {
	text-align: center;
	margin-top: 60px;
}

.landing .landing-header .buttons .btn {
	padding: 18px 32px;
}

.landing .landing-header .buttons .btn + .btn {
	margin-left: 50px;
}

.landing .landing-header .buttons .col1 {
	text-align: right;
}

.landing .landing-header .buttons .col3 {
	text-align: left;
}

/* >> LANDING HEADER */

/* << GRANTS */

.landing .grants {
	/* height: 300px; */
	background-image: url('../img/landing-grants-bg-1x.png');
	margin-top: 114px;
	padding-bottom: 60px;
}

.landing .grants > .container {
	background-image: url('../img/laptop-4x-min.png');
	background-size: 650px;
	background-repeat: no-repeat;
	background-position-x: right;
	background-position-y: 50px;
	padding-bottom: 120px;
}

.landing .grants {
	padding-top: 60px;
}

.landing .grants .section-body {
	margin-top: 40px;
}

.landing .grants .grants-item {
	font-family: 'montserrat-500';
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	padding-left: 50px;
	background-image: url('../img/check-mark-4x.png');
	background-repeat: no-repeat;
	background-size: 26px;
	background-position-y: center;
	min-height: 26px;
}

.landing .grants .grants-item + .grants-item {
	margin-top: 20px;
}

.landing .grants .laptop-img {
	width: 650px;
	height: 587px;
	background-image: url('../img/laptop-4x-min.png');
	background-size: 650px;
	background-repeat: no-repeat;
}

/* >> GRANTS */

/* << GRANTWRITING */

.landing .grantwriting {
	color: var(--white);
	/* min-height: 250px; */
	background-image: url('../img/landing-grantwriting-bg.png');
	background-repeat: repeat-x;
	padding-top: 60px;
	padding-bottom: 60px;
	text-align: center;
}

.landing .grantwriting .section-header {
	color: var(--white);
}

/* >> GRANTWRITING */

/* << IT-AUDIT */

.landing .audit {
	padding-top: 30px;
	padding-bottom: 50px;
	background-image: url(../img/audit-bg-1x.png);
}

.landing .audit .section-header {
	margin-top: 40px;
	margin-bottom: 40px;
}

.landing .audit .items {
	text-align: center;
	box-shadow: var(--card-shadow);
}

.landing .audit .items .item {
	white-space: pre-line;
}

.landing .audit .items .item + .item {
	margin-top: 20px;
}

.landing .audit .items .header {
	text-align: center;
	margin: 0;
	padding: 0;
	margin-bottom: 20px;
}

.landing .audit .items .header .icon {
	margin-bottom: 20px;
}

.landing .audit .problem {
	/* width: 310px; */
}

.landing .audit .problem .header .icon {
	content: url('../img/problem-icon.png');
	width: 60px;
}

.landing .audit .solve {
	background-color: var(--blue);
	color: var(--white);
}

.landing .audit .solve .header .title {
	color: var(--white);
}

.landing .audit .solve .header .icon {
	width: 97px;
	content: url('../img/solve-icon.png');
}

.landing .audit .gain .header .icon {
	width: 66px;
	content: url('../img/gain-icon.png');
}

.landing .audit .problem-col {
	/* width: 390px; */
}

.landing .audit .solve-col {
	/* width: 456px; */
}

.landing .audit .gain-col {
	/* width: 390px; */
}

/* >> IT-AUDIT */

/* << AUDIT-SUCCESS */

.landing .audit-success {
	padding-top: 50px;
	padding-bottom: 50px;
	/* background-image: url('../img/audit-success-bg-4x-min.png'); */
	background-size: contain;
}

.landing .audit-success .item {
	background-color: var(--blue);
	color: var(--white);
	text-align: center;
}

.landing .audit-success .item .title {
	color: var(--white);
	white-space: pre-line;
	font-size: 24px;
}

.landing .audit-success .item p {
	font-family: 'montserrat-400';
}

.landing .audit-success .item {
	/* width: 310px; */
	min-height: 330px;
}

/* >> AUDIT-SUCCESS */

/* << DEV */

.landing .dev {
	padding-top: 50px;
}

.landing .dev .graph-icon-box {
	text-align: center;
	margin-top: 30px;
}

.landing .dev .graph-icon-box .graph-icon {
	content: url('../img/graph-icon-4x.png');
	width: 60px;
}

.landing .dev .market-situation-header {
	text-align: center;
	font-family: 'onest-700', serif;
	font-size: 24px;
	margin-top: 10px;
}

.landing .dev .market-situation-list {
	margin-top: 20px;
	text-align: center;
	font-family: 'montserrat-400';
}

.landing .dev .market-situation-list .item {
	margin-top: 10px;
}

.landing .dev .your-result {
	text-align: center;
	margin-top: 40px;
}

.landing .dev .your-result .title {
	margin-top: 10px;
}

.landing .dev .your-result .prize-icon {
	width: 64px;
	content: url('../img/prize-icon-4x.png');
}

.landing .dev .your-result .items {
	margin-top: 30px;
	/* position: relative; */
	margin-top: -5px;
	overflow-x: hidden;
}

.landing .dev .your-result .items .item .icon {
	font-family: 'onest-700', serif;
	font-size: 48px;
	color: var(--white);
	background-color: var(--blue);
	width: 103px;
	height: 103px;
	padding-top: 15px;
	border-radius: 10px;
	margin: auto;
}

.landing .dev .your-result .items .item .text {
	text-align: center;
	font-family: 'montserrat-400';
	font-size: 16px;
	margin-top: 10px;
}

.landing .dev .your-result .items .hr {
	height: 5px;
	background: var(--blue);
	/* width: 999px; */
	width: 80%;
	margin: auto;
	position: relative;
	top: 50px;
	z-index: -1;
}

/* >> DEV */

/* << SAMPLES */

.landing .dev-samples {
	margin-top: 50px;
	padding-top: 50px;
	background: 
		url('../img/devices-1x.png') center center no-repeat,
		url('../img/samples-bg-1x.png') top center no-repeat
	;
	min-height: 800px;
}

.landing .dev-samples .section-header {
	font-size: 32px;
}

.landing .dev-samples .LandingDevSample.amalthea-m {
	background-image: url(../img/amalthea-icon-4x-min.png);
}

.landing .dev-samples .LandingDevSample.owl-school {
	background-image: url(../img/owl-school-icon-4x.png);
}

.landing .dev-samples .LandingDevSample.owl-school .card-body {
	padding-top: 45px;
}

.landing .dev-samples .LandingDevSample.asdik {
	background-image: url(../img/asdik-icon-4x.png) ;
}

.landing .dev-samples .LandingDevSample.asdik .card-body {
	padding-top: 50px;
}

.landing .dev-samples .LandingDevSample.studerus {
	background-image: url(../img/studerus-icon-4x.png) 
}

.landing .dev-samples .LandingDevSample.studerus .card-body {
	padding-top: 20px;
	padding-bottom: 20px;
}

.landing .dev-samples .LandingDevSample.lily {
	background-image: url(../img/lily-icon-4x.png) 
}

.landing .dev-samples .LandingDevSample.lily .card-body {
	padding-top: 42px;
}

.landing .dev-samples .LandingDevSample.owl-paint {
	background-image: url(../img/owl-paint-icon-4x.png) 
}

.landing .dev-samples .LandingDevSample.owl-paint .card-body {
	padding-top: 50px;
}

.landing .dev-samples .LandingDevSample.sputnik {
	background-image: url(../img/satellite-icon-4x.png) 
}

.landing .dev-samples .LandingDevSample.sputnik .card-body {
	padding-top: 50px;
}

/* >> SAMPLES */

/* << CONTACTS */

.landing .contacts {
	padding-top: 50px;
}

.landing .contacts .section-header {
	margin-bottom: 40px;
}

/* >> CONTACTS */

/* << claim form box */

.landing .claim-form-box {
	/* padding-top: 60px; */
	/* padding-bottom: 60px; */
	/* margin: 60px auto; */
	margin: auto;
	padding: 18px 26px 20px;
	/* padding: 18px 15px 20px; */
	/* padding: 20px 15px; */
	width: 362px;
	max-width: 90%;
	/* padding-left: 10px; */
	/* padding-right: 10px; */
	border-radius: 10px;
	box-shadow: 0px 0px 30px 0px rgba(38, 84, 214, 0.30);
	background-color: #fff;
}

.landing .claim-form .h1 {
	color: var(--Blue, #2654D6);
	font-family: 'onest-700';
	font-size: 24px;
	font-style: normal;
	/* font-weight: 700; */
	line-height: normal;
	text-align: center;
}

.landing .claim-form .or-text {
	color: var(--dark-blue);
	margin-top: 15px;
	margin-bottom: 15px;
}

.landing .claim-form .controls {
	text-align: center;
	margin-top: 30px;
}

.landing .claim-form .controls .submit-btn {
	padding: 18px 32px;
	display: inline-block;
	/* margin: auto; */
}

.landing .claim-form .form-group {
	margin-top: 20px;
	margin-bottom: 20px;
}

.landing .claim-form.not-filled .submit-btn {
	background-color: var(--btn-inactive-bg);
}

.landing .claim-grant {
	padding-top: 60px;
	padding-bottom: 60px;
}

.landing .claim-audit {
	background-image: url(../img/claim-form-section-bg-1x.png);
	padding-top: 30px;
	padding-bottom: 30px;
}

.landing .claim-dev {
	padding-top: 60px;
	padding-bottom: 60px;
}

/* >> claim form box */

/* << director */

.landing .director {
	padding-top: 50px;
	padding-bottom: 20px;
	background-image: url(../img/director-bg-1x.png);
	background-position: top center;
	background-repeat: repeat-y;
}

.landing .director .info {
	font-family: 'onest-700';
	font-size: 24px;
}

.landing .director .info .name {
	text-align: center;
	margin-bottom: 40px;
}

.landing .director .info .img-col {
	text-align: right;
}

.landing .director .features {
	/* text-align: left; */
	/* width: 50%; */
	/* margin: auto; */
	white-space: pre-line;
	/* margin-top: 20px; */
	text-align: left;
}

.landing .director .features .item {
	margin-top: 20px;
}

.landing .director .features .item::first-letter {
	text-transform: capitalize;
	margin-top: 20px;
}

.landing .director .head .info .name {
	font-size: 36px;
}

.landing .director-contacts {
	text-align: center;
}

.landing .director-contacts .item {
	display: inline-block;
	margin-left: 20px;
	font-family: 'onest-500';
	letter-spacing: 0.64px;
	color: var(--blue);
}

/* >> director */

/* << director-details */

.landing .director-details > .container {
	padding-left: 100px;
	padding-right: 100px;
}

.landing .director-details .skills .head {
	/* text-align: center !important; */
}

.landing .director-details .skills .skill {
	/* text-align: center; */
}

.landing .director-details .skills .skill .skill-name {
	font-size: 20px;
}

.landing .director-details .skills .skill .skill-item {
	margin-top: 20px;
}

.landing .director-details .skills > .head {
	margin-bottom: 20px;
}

.landing .director-details .skills .skill-box {
	text-align: left;
}

.landing .director-details .skills .skill-box + .skill-box {
	margin-top: 20px;	
}

.landing .director-details .skills .skill-box.col-12 .skill-item {
	margin-top: 0;
}

.landing .director-details .career {
	/* padding-top: 60px; */
}

.landing .director-details .career .head {
	/* padding-top: 30px; */
	text-align: left;
}

.landing .director-details .career .item {
	margin-top: 20px;
}

.landing .director-details-item {
	margin-top: 60px;
	font-family: onest-400;
}

.landing .director-details-item .head {
	text-align: left;
}

.landing .dmitrij-img {
	content: url(../img/dmitrij1.jpg);
	max-width: 100%;
	border-radius: 10px;
	box-shadow: var(--card-shadow);
}

.landing .director-details-toggle-btn-box {
	text-align: center;
	margin-top: 36px;
}

.landing .director-details-toggle-btn {
	font-family: onest-700;
	font-size: 20px;
	color: var(--blue);
}

.landing .director-details-toggle-btn .icon {
	margin-left: 8px;
}

.landing .director-details-toggle-btn.details-shown .arrow-down-icon {
	display: none;
}

.landing .director-details-toggle-btn.details-hidden .arrow-up-icon {
	display: none;
}

/* >> director-details */

.landing .sub-slogan .item + .item {
	margin-top: 12px;
}

