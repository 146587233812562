.LandingContactsItem .name {
    color: var(--blue);
    font-size: 20px;
    font-family: 'montserrat-700';
    margin-bottom: 20px;
    text-align: center;
}

.LandingContactsItem .contact-box {
    text-align: center;
    margin-bottom: 20px;
    
}

.LandingContactsItem .contact {
    color: var(--blue);
    font-size: 16px;
    font-family: 'montserrat-600';
    padding: 10px 0 20px 40px;
    background-position: left center;
    background-size: 32px 32px;
    background-repeat: no-repeat;
}

.LandingContactsItem .phone {
    background-image: url('../img/phone-icon-4x.png');
}

.LandingContactsItem .telegram {
    background-image: url('../img/telegram-icon-4x.png');
}

.LandingContactsItem .email {
    background-image: url('../img/email-icon-4x.png');
}