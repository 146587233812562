@media (max-width: 1530px) {
    
    .TopMenu .navbar-nav {
        margin-left: 0px;
        padding-right: 30px;
        padding-left: 30px;
    }
    
    .TopMenu .logos .logo-link {
        display: block;
        text-align: center;
    }
    
    .TopMenu .logos .airse-logo-link {
        margin-bottom: 10px;
    }
    
    .TopMenu .logos .airse-logo-link img {
        margin-right: -50px;
    }
    
    .TopMenu .email-col {
        padding-left: 0;
        padding-bottom: 10px;
    }
    
}

@media (max-width: 991px) {
    
    /* todo !!! repair toggler */
    
    .TopMenu .navbar-toggler {
        display: none;
    }
    
    .TopMenu .navbar-brand {
        width: 100%;
        text-align: center;
        margin: 0;
        margin-left: 0 !important;
    }
    
    .TopMenu .navbar-brand + .navbar-brand {
        margin-top: 30px;
    }
    
    .TopMenu .logos {
        margin: auto;
    }
    
    .TopMenu .logos .airse-logo-link img {
        margin-right: -20px;
    }
    
}