/* LG- */
@media (max-width: 1199px) {
	
	.landing .director .lang-picker {
		margin-left: 0;
		display: block;
		margin-top: 20px;
	}
	
	.landing .grants .container {
		background-image: none;
	}
	
}

/* SM- */
@media (max-width: 767px) {
	
	.landing .landing-header .slogan {
		font-size: 32px !important;
		margin-top: 30px;
		margin-bottom: 0;
	}
	
	.landing .landing-header .sub-slogan {
		font-size: 20px;
		white-space: normal;
	}
	
	.landing h2 {
		font-size: 32px;
	}
	
	.landing .landing-header .buttons {
		margin-top: 20px;
	}
	
	.landing .landing-header .buttons .btn {
		width: 100%;
	}
	
	.landing .landing-header .buttons .col1 {
		text-align: center !important;
		padding-bottom: 20px;
	}
	
	.landing .landing-header .buttons .col3 {
		text-align: center !important;
		padding-top: 20px;
	}
	
	.landing .grants .container {
		background-image: none;
	}
	
	.landing .audit .items {
		margin-bottom: 20px;
	}
	
	.landing .audit-success .item {
		margin-bottom: 20px;
	}
	
	.landing .dev .your-result .item {
		/* margin-bottom: 20px; */
		margin-top: 20px;
	}
	
	.landing .LandingContactsItem:first-of-type {
		margin-bottom: 40px;
		padding-bottom: 20px;
		border-bottom: 1px dotted var(--blue);
	}
	
	.landing .grants {
		margin-top: 30px;
	}
	
	.landing .director-details > .container {
		padding-left: 15px;
		padding-right: 15px;
	}
	
	.landing .director-contacts .item {
		display: block;
	}
	
	.landing .director .info .img-col {
		text-align: center;
	}
	
	.pre-line {
		white-space: normal;
	}
	
	.landing .director .info {
		font-size: 16px;
	}
	
}